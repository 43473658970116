.vahterus-input-wrapper {
	position: relative;
	width: 100%;

	display: flex;
	align-items: baseline;
}

.ant-input-search-button {
	border: none;
}

.input-search-underline,
.input-underline,
.input-number-underline {
	$color-input-underline: $color-primary;
	$color-input-underline-active: $color-primary;
	$color-input-underline-hot: $color-orange-2;
	$color-input-background-hot: rgba($color-orange-2, 0.05);
	$color-input-underline-cold: $color-blue-3;
	$color-input-underline-output: $color-green;
	$color-input-background-cold: rgba($color-blue-3, 0.05);
	$color-input-background-output: rgba($color-green, 0.1);

	input:disabled,
	&.ant-input[disabled] {
		color: $color-black !important;
	}

	border: none;
	border-radius: 0;
	border-bottom: 1px solid $color-input-underline;
	font-size: $font-size-s;

	&:focus,
	&:hover {
		border-color: $color-input-underline-active;
		box-shadow: none !important;
	}

	.ant-input {
		border: none;
		background-color: transparent;
	}

	.ant-input-group-addon {
		background-color: $color-white;
		border: none;
	}

	//================================
	// Hot/Cold input
	//=================================
	&-hot {
		border-bottom: 1px solid $color-input-underline-hot;

		&:focus,
		&:hover {
			border-color: $color-input-underline-hot;
		}
	}

	&-cold {
		border-bottom: 1px solid $color-input-underline-cold;

		&:focus,
		&:hover {
			border-color: $color-input-underline-cold;
		}
	}

	&-output {
		background-color: $color-input-background-output !important;
		border-bottom: 1px solid $color-input-underline-output;
		color: $color-black;

		&.input-number-underline-hot {
			background-color: $color-input-background-hot !important;
			border-bottom: 1px solid $color-input-underline-hot;
		}

		&.input-number-underline-cold {
			background-color: $color-input-background-cold !important;
			border-bottom: 1px solid $color-input-underline-cold;
		}
	}

	//================================
	// Tooltip
	//=================================
	&__tooltip {
		position: absolute;
		top: 5px;
		right: 2px;

		img {
			width: 10px;
			height: 10px;
		}
	}
}

.input-number-underline {
	&__addonAfter {
		margin-left: 8px;
		color: $color-text-gray;
		white-space: nowrap;
		flex-basis: 50px;
	}

	.ant-input-number-handler-wrap {
		display: none;
	}
}
