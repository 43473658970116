.calculation-button-row,
.calculation-button-row-sticky {
	display: flex;
	justify-content: flex-start;
	padding-top: 42px;
	padding-bottom: 42px;

	.vahterus-button:not(:last-child) {
		margin-right: 8px;
	}

	.save-menu {
		&:hover {
			background-color: $color-white;
			color: $color-primary;
		}
	}
}

.calculation-button-row {
	padding-left: 20%;
	&.visible {
		opacity: 0;
		visibility: hidden;
	}
}

.calculation-button-row-sticky {
	z-index: 999;
	position: fixed;
	top: 36px;
	left: 20%;
	opacity: 0;
	visibility: hidden;
	transition: all 0.1s ease-in;

	&.visible {
		opacity: 1;
		visibility: visible;
	}
}
