$application-details-header-size: $font-size-s;

.application-detail {
	background-color: $color-white;
	display: flex;
	justify-content: space-between;
	padding: 0 30px;

	&__sizing-version {
		position: absolute;
		top: 35%;
		right: 3%;
	}

	&__form {
		margin-bottom: 24px;
		table {
			width: 100%;

			th {
				color: $color-gray-2;
				font-weight: $font-weight-normal;
				padding: 8px 6px;
			}

			td {
				padding: 8px 0;
			}
		}
	}
}
