/**
 * Sass Variables
 */

// COLOR
$color-blue: #00006e;
$color-white: #ffff;
$color-black: #000000;
$color-gray-1: #f9f9f9;
$color-gray-2: #727272;
$color-gray-3: #dbdbdb;
$color-gray-4: #e5e5e5;
$color-gray-5: #c4c4c4;
$color-gray-6: #8a8a8a;
$color-gray-7: #e0e0e0;
$color-orange: #ff8267;
$color-orange-2: #f2994a;
$color-green: #6fcf97;
$color-blue-2: #1890ff;
$color-blue-3: #2d9cdb;

$color-primary: $color-black;
$color-text-gray: $color-gray-2;
$color-text-black: $color-black;
$color-text-blue: $color-blue;
$color-text-border: $color-gray-3;
$color-divider: $color-gray-7;
$color-background-gray: rgba($color-gray-4, 0.2);
$color-table-border: $color-gray-3;
$color-hot-switch: $color-orange-2;
$color-cold-switch: $color-blue-2;

// FONT
$font-size-xs: 10px;
$font-size-s: 12px;
$font-size-m: 14px;
$font-size-lg: 16px;
$font-size-xlg: 18px;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;

$default-font-size: $font-size-s;
