.page-workspace {
	.page-section {
		margin: 37px 0;
	}

	.create-offer-button {
		display: flex;
		justify-content: flex-end;
	}
}
