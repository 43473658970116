#grid-content {
	overflow-x: scroll;
	display: grid;

	*:not(h3, svg) {
		font-size: $font-size-s;
	}
	.row-header-wrapper {
		position: sticky;
		top: 0;
		left: 0px;
		padding-left: 24px;
		background-color: white;
		z-index: 200;

		.ai-labels {
			&__top {
				padding-top: 154px;
				z-index: 100;

				li {
					border-bottom: none;
					padding: 24.55px 0;
				}
			}

			&__indented {
				background-color: white;

				li {
					border-bottom: none;
					padding: 24.55px 0 24.55px 24px;
				}
			}

			li {
				border-bottom: none;
				padding: 24.55px 0;

				&.indented {
					padding-left: 24px;
				}
			}
		}
	}
}

// .ai-result-table {
// 	th {
// 		text-align: left;
// 	}
// 	th,
// 	td {
// 		padding: 5px 15px 5px 15px;
// 	}
// }

/* Hide scrollbar for Chrome, Safari and Opera */
#grid-content::-webkit-scrollbar {
	display: none;
	position: sticky;
	bottom: 0;
}

/* Hide scrollbar for IE, Edge and Firefox */
#grid-content {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

/* SKELETON */

.skeleton {
	animation: skeleton-loading 1s linear infinite alternate;
	max-width: 400px;
	height: 100%;
	margin-right: 24px;
	border-radius: 0;
}

@keyframes skeleton-loading {
	0% {
		background-color: hsl(200, 20%, 80%);
	}
	100% {
		background-color: hsl(200, 20%, 92%);
	}
}

.skeleton-text {
	color: transparent;
	width: 100%;
	margin-bottom: 0.5rem;
	border-radius: 0.25rem;
}
