.capacity-temp-points {
	//background-color: yellow;
	&__labels {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 28px;
		margin-left: 18px;
		align-items: end;

		h3 {
			font-size: $font-size-m;
			font-weight: $font-weight-bold;
			width: 118px;
		}

		.hot-label {
			font-size: $font-size-m;
			color: $color-hot-switch;
			font-weight: $font-weight-bold;
		}

		.cold-label {
			font-size: $font-size-m;
			color: $color-cold-switch;
			font-weight: $font-weight-bold;
		}
	}
}
