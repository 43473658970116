$project-details-header-size: $font-size-s;

.project-details {
	&__header {
		color: $color-primary;
		font-weight: bold;
		font-size: $font-size-lg;
		margin-right: 24px;
		margin-bottom: 16px;
	}

	.offer-form {
		border-bottom: 1px solid $color-divider;
		display: grid;
		grid-template-columns: 7fr 1fr;
		grid-column-gap: 24px;

		// adjust success/error/warning icon
		.ant-form-item-children-icon {
			right: 24px;
		}

		&__items-container {
			display: grid;
			grid-template-columns: repeat(6, 1fr);
			grid-column-gap: 24px;
			grid-row-gap: 24px;
		}

		&__button-container {
			display: flex;
			justify-content: flex-end;
		}

		input {
			font-size: $font-size-s;
		}

		.ant-form-item {
			flex: 1;
			margin-right: 24px;

			&-label {
				label {
					font-size: $font-size-s;
					color: $color-gray-2;
				}
			}

			&-control {
			}
		}

		.text-item {
			//margin-right: 24px;
			flex: 1;
			font-size: $font-size-s;

			&-label {
				font-size: $font-size-s;
				color: $color-gray-2;
				margin-bottom: 20px;
				display: block;
			}

			&-value {
			}
		}
	}

	div.scrollable-list-wrapper {
		max-height: 160px;

		p {
			font-size: 12px;
			color: #727272;
		}
		ul.ant-list-items li {
			display: flex;
			font-size: 12px;
			padding: 1px;
		}

		button {
			font-size: 12px;
			border: none;
			padding: 0;
			justify-content: flex-end;

			svg {
				font-size: 12px;
			}
		}

		::-webkit-scrollbar {
			width: 10px;
		}
		::-webkit-scrollbar-track {
			background: #f1f1f1;
		}
		::-webkit-scrollbar-thumb {
			background: #888;
		}
		::-webkit-scrollbar-thumb:hover {
			background: #555;
		}
	}
}
