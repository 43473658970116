/**
 * Reset/normalize
 *
 */

*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

body {
	box-sizing: border-box;
	font-size: $font-size-s;
}

a {
	color: $color-primary;
	text-decoration: underline;

	&:link,
	&:active,
	&:visited,
	&:hover {
		color: $color-primary;
		text-decoration: underline;
	}
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
	border-bottom: none !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after {
	border-bottom: none !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
	border-bottom: solid 3px #000000 !important;
	color: #000000 !important;
}

.ant-menu-item:hover {
	color: black !important;
}
.ant-select-item-option-content {
	white-space: normal;
	font-size: $font-size-s;
}
