@import '~antd/dist/antd.css';

.ant-modal {
	.ant-modal-confirm-title {
		font-size: $font-size-m;
	}
}

.ant-modal-confirm-btns {
	.ant-btn:hover,
	.ant-btn:focus {
		color: $color-primary;
		background: $color-white;
		border-color: $color-primary;
	}

	.ant-btn-primary:hover,
	.ant-btn-primary:focus {
		color: $color-white;
		background: $color-primary;
		border-color: $color-primary;
	}
}

//================================
// override messange loading
//=================================
.ant-message-info .anticon,
.ant-message-loading .anticon {
	color: $color-primary;
}

//================================
// override button
//=================================
.ant-btn-primary {
	background-color: $color-primary;
	background: $color-primary;

	&:hover,
	&:focus {
		background-color: $color-primary;
		color: $color-white;
	}
}
//================================
// override radio button
//=================================

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
	border-color: $color-primary;
}

.ant-radio-checked .ant-radio-inner {
	border-color: $color-primary;

	&:after {
		background-color: $color-primary;
	}
}

//================================
// override checkbox
//=================================
.ant-checkbox-checked .ant-checkbox-inner {
	background-color: $color-primary !important;
	border-color: $color-primary;
}

//================================
// override spinner
//=================================
.ant-spin-dot-item {
	background-color: $color-primary;
}

//================================
// override checkbox
//=================================
$color-checkbox: $color-green;

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
	background-color: $color-checkbox;
}

.ant-checkbox-checked .ant-checkbox-inner {
	background-color: $color-checkbox;
	border-color: $color-checkbox;
}

//================================
// override tab
//=================================

.ant-tabs {
	font-size: $font-size-s;
}

.ant-tabs-tab {
	background: white !important;
	border: 1px solid $color-gray-5 !important;
	border-bottom-color: $color-primary !important;
	color: $color-gray-5 !important;
	width: 165px;
	display: flex;
	justify-content: center;
	position: relative;

	&:not(:last-of-type) {
		margin-right: 8px !important;
	}

	&:hover {
		color: $color-primary;
	}

	&.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: $color-primary;
	}

	.ant-tabs-tab-btn:focus,
	.ant-tabs-tab-remove:focus,
	.ant-tabs-tab-btn:active,
	.ant-tabs-tab-remove:active {
		color: $color-primary;
	}

	.ant-tabs-tab-remove {
		color: $color-gray-5;
		position: absolute;
		right: 16px;
	}

	&.ant-tabs-tab-active {
		opacity: 1;
		border: 1px solid $color-primary !important;
		border-bottom-color: transparent !important;

		&.ant-tabs-tab-btn {
			color: $color-primary;
		}

		.ant-tabs-tab-remove {
			color: $color-primary;
		}
	}
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
	color: $color-primary;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
	border-bottom: 1px solid $color-primary;
}

.ant-tabs-nav {
	margin-bottom: 0 !important;
	margin-left: 12px;

	.ant-tabs-nav-wrap {
		margin-left: 24px;
	}
}

.ant-tabs-nav-add {
	background-color: $color-primary !important;
	border: 1px solid $color-primary !important;
	width: 165px;
	color: $color-white;

	&:hover {
		color: $color-white !important;
	}
}

//================================
// override table
//=================================

$font-size-table: $font-size-s;

.ant-table {
	font-size: $font-size-table;
	th.ant-table-cell {
		color: $color-gray-2;
		background-color: $color-gray-7;
	}

	.cell-highlighted {
		color: $color-primary;
	}

	.ant-table-row-expand-icon:focus,
	.ant-table-row-expand-icon:hover {
		color: $color-primary;
	}
}

//================================
// override pagination
//=================================
.ant-pagination {
	&-item {
		&:hover,
		&-active {
			border-color: $color-primary;

			a {
				color: $color-primary;
			}
		}
	}
}

.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
	color: $color-primary;
	border-color: $color-primary;
}
//================================
// override ant-table-tbody
//=================================
.ant-table-thead > tr > th {
	border-bottom: 1px solid $color-gray-3;
}
.ant-table-tbody > tr > td {
	border-bottom: 1px solid $color-gray-3;
}
.ant-table-tbody > tr > td {
	border-bottom: 1px solid $color-gray-3;
}

//================================
// override ant-menu-horizontal
//=================================

.ant-menu-horizontal:not(.ant-menu-dark) {
	> .ant-menu-item-selected {
		border-bottom: solid 3px $color-primary;
		color: $color-primary;
	}
	> .ant-menu-item:hover {
		border-bottom: solid 3px $color-primary;
		color: $color-primary;
	}

	& a {
		color: $color-primary;
	}
	> .ant-menu-item a:hover {
		color: $color-primary;
	}

	> .ant-menu-submenu .ant-menu-submenu-title:hover {
		color: $color-primary;
	}

	> .ant-menu-submenu:hover {
		border-bottom: solid 3px $color-primary;
		color: $color-primary;
	}
}

.ant-menu-sub:hover {
	li {
		color: $color-primary !important;
	}
}

//================================
// override ant switch
//=================================
.ant-switch {
	border-radius: 0;

	&-handle:before {
		border-radius: 0;
	}
}

//================================
// override ant select
//=================================
.ant-select {
	font-size: $font-size-s;

	.ant-select-selector {
		border-radius: 0 !important;
	}
}
