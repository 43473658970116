.position-table {
	$color-position-table-header: $color-gray-7;
	$color-calculation-table-header: rgba($color-gray-7, 0.3);

	.ant-table th.ant-table-cell {
		background-color: $color-position-table-header;
		color: $color-gray-2;
	}

	.calculation-table {
		.ant-table th.ant-table-cell {
			background-color: $color-calculation-table-header;
			color: $color-gray-2;
		}
	}

	.table-row-added {
		background-color: rgba($color-green, 0.2);
	}

	&__header {
		font-weight: bold;
		font-size: $font-size-lg;
		margin-right: 24px;
		margin-bottom: 0;
	}

	&__actions {
		display: flex;
		align-content: center;
		margin-bottom: 16px;

		.vahterus-button {
			margin-right: 16px;
		}

		.button-create-position {
			margin-left: auto;
		}
	}

	.button-create-calculation {
		float: right;
	}
}

tr.dragging-table-row {
	width: 100%;
	display: flex;
	justify-content: space-around;
	background: #fafafa;
	border: 1px solid #ccc;
	padding: 20px;
}

ol.position-empty-text {
	font-size: $font-size-m;
	display: block;
	margin-top: 50px;
	margin-left: 18px;

	li {
		padding-bottom: 10px;
	}
}

p.position-empty-text {
	font-size: $font-size-m;
	display: flex;
	justify-content: center;
	margin-top: 50px;
}

.position-comment-container {
	height: 200px;
	display: flex;
	flex-direction: column;
	overflow: auto;
	margin-bottom: 2em;
	.sender {
		font-size: 11px;
		color: gray;
	}
}
