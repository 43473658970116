.vahterus-header {
	background: $color-white;
	height: auto;
}
.vahterus-header-top-row {
	display: flex;
	border-bottom: 1px solid $color-gray-3;
}

.vahterus-logo {
	width: max-content;
}

.vahterus-header-menu {
	justify-content: flex-end;
	display: flex;
	border: 0;
	flex-grow: 1;
}

.vahterus-header-search {
	width: 400px;

	// takes away the blue focus border around select/dropdown
	.ant-select-focused,
	.ant-select-selector {
		border: none !important;
		border: 1px solid $color-gray-3 !important;
		box-shadow: none !important;
	}
}

.vahterus-header-page-nav-menu {
	border-bottom: 1px solid $color-gray-3;
}

.vahterus-header-page-nav-menu {
	& ul {
		height: 50;
	}
	& li {
		width: 180px;
		text-align: center;
		margin: 0 !important;
		padding: 0 20px !important;
	}
}

.vahterus-header-page-nav-menu .ant-menu-item-selected {
	background-color: $color-gray-1;
	font-weight: bold;
}

@include respond(tab-port) {
	.vahterus-logo {
		display: none;
	}
	.vahterus-header-search-menu-item {
		width: auto;
	}
}
.banner-top {
	background: #000169;
	height: 50px;
	display: flex;
	justify-content: center;
	text-align: center;

	.ant-alert-message {
		color: #fff;
		font-weight: 700;
	}

	.ant-alert-info {
		background: #000169;
	}
}
