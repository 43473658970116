.multi-condenser-drawings-wrapper {
	display: flex;
	.drawing {
		margin: 40px 20px !important;

		.pshe-label {
			position: absolute;
			top: -32px;
			left: 50%;
			transform: translateX(-50%);
			margin: 0;
			padding: 0;
			font-size: $font-size-s;
			font-weight: 700;
		}
		.unit-label {
			position: absolute;
			bottom: -32px;
			left: 50%;
			transform: translateX(-50%);
			margin: 0;
			padding: 0;
			font-size: $font-size-s;
		}
	}
}

.drawing {
	margin: 12px;
	width: 200px;
	height: 100px;
	position: relative;
	border: $color-black solid 1px;

	&__straight-lines--up {
		position: absolute;
		top: -15px;
		&--left {
			left: 0px;
		}
		&--right {
			right: 0px;
		}
	}

	&__straight-lines--down {
		position: absolute;
		bottom: -25px;
		&--left {
			left: 0px;
		}
		&--right {
			right: 0px;
		}
	}

	&__plate {
		background-color: $color-gray-7;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50%;
		transform: translateY(50%);
	}

	&__arrow-container {
		position: absolute;
		display: flex;
		justify-content: space-around;

		&--up {
			left: 0;
			right: 0;
			bottom: 100%;
		}

		&--up--middle {
			left: 50%;
			bottom: 100%;
			right: auto;
		}

		&--down {
			left: 0;
			right: 0;
			top: 100%;
		}

		&--down--middle {
			left: 50%;
			bottom: 100%;
			right: auto;
		}

		&--down-in {
			left: 0;
			right: 0;
			bottom: 5px;

			.drawing__icon {
				transform: rotate(45deg) translateX(8px);
			}
		}

		&--left {
			top: 25%;
			bottom: 25%;
			right: 100%;
			flex-direction: column;
		}

		&--right {
			top: 25%;
			bottom: 25%;
			left: 100%;
			flex-direction: column;
		}

		&--evaporator-inlet {
			justify-content: space-between;
			top: 0;
			bottom: 75%;
			left: -16px;
			right: -16px;
		}

		&--evaporator-combinedC-outlet {
			justify-content: space-between;
			flex-direction: row-reverse;
			top: 0;
			bottom: 75%;
			left: 0;
			right: 0;
		}
	}

	&__icon {
	}
}
