.search-properties-form {
	width: 100%;
	margin-top: 16px;
	box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.08);
	background-color: $color-white;

	.ant-form-item {
		margin-bottom: 0;
	}

	&__header {
		h2 {
			padding-top: 16px;
			margin-left: 16px;
			margin-bottom: 24px;
			margin-bottom: 16px;
			font-weight: bold;
			font-size: $font-size-lg;
			width: 100%;
		}

		.search-info-banner {
			margin: 16px;

			.ant-alert-message {
				font-size: $font-size-m;
			}
			.ant-alert-description {
				font-size: $font-size-s;
			}
		}
	}

	&__grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 16px;
		margin-top: 16px;
		margin-bottom: 16px;
		max-width: 75%;

		padding: 16px;

		.input-group {
			padding-bottom: 48px;
			height: 100%;
			min-width: 400px;
			margin-right: 16px;

			@include respond(phone) {
				grid-template-columns: 1fr;
			}
		}
	}

	&__button-row {
		margin: 16px;
		display: flex;
		justify-self: end;
		float: right;

		.vahterus-button {
			text-transform: uppercase;
		}

		.vahterus-button:not(:last-child) {
			margin-right: 8px;
		}
	}

	//==========================================
	// Input style
	//==========================================

	label {
		font-size: $font-size-s;
		white-space: normal;
		word-wrap: break-word;
		height: 40px;
	}

	input,
	.ant-select {
		font-size: $font-size-s;
		width: 100%;
	}

	.input-number-underline,
	.input-underline {
		width: 100%;
	}

	.input-number-underline {
		height: 33px;
	}

	.ant-form-item-control-input-content {
		display: flex;
	}

	.ant-form-item {
		margin-bottom: 6px;
		flex: 1;

		&:not(:last-child) {
			margin-right: 4px;
		}

		&.required-group .ant-form-item-label:before {
			display: inline-block;
			margin-right: 4px;
			color: #ff4d4f;
			font-size: 14px;
			font-family: SimSun, sans-serif;
			line-height: 1;
			content: '*';
		}

		&-label {
			width: 160px;
		}

		&-control {
			flex: 1;
		}
	}

	//==========================================
	// Hot cold
	//==========================================
	.hot-cold-inputs {
		padding-top: 10px;
		padding-bottom: 15px;
	}

	//==========================================
	// Error message
	//==========================================
	&__error {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 24px;
		margin-right: 24px;
	}

	.required-alert-wrapper {
		display: flex;
		justify-content: flex-start;

		.required-alert {
			margin-top: 15px;
			padding: 7px 15px;
			text-align: left;
		}
	}
}

//==========================================
// Results Table
//==========================================

.search-results-table {
	$color-button-primary: $color-primary;
	$color-button-primary-hover: $color-white;

	&__header {
		font-weight: bold;
		font-size: $font-size-lg;
		margin-top: 20px;
		margin-right: 24px;
		margin-bottom: 0;
	}

	.search-results-table-dropdown-button {
		color: $color-button-primary;
		width: 100%;
		height: 100%;
	}

	.search-results-table-dropdown-button:hover {
		color: $color-button-primary-hover;
		background-color: $color-button-primary;
	}
	.search-results-table-dropdown-button:focus {
		color: $color-button-primary-hover;
		background-color: $color-button-primary;
	}

	.search-results-table-dropdown-button-icon {
		font-size: 1.5em;
	}
}
