.data-sheet {
	display: flex;
	flex-direction: column;

	&:not(:last-child) {
		margin-bottom: 24px;
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;

		h2 {
			font-weight: $font-weight-bold;
		}
	}

	&__logo {
		width: 200px;
		height: auto;
		object-fit: contain;
		margin-bottom: 24px;
	}

	.thermal-design-section {
		position: relative;
	}

	&__drawing {
		position: absolute;
		top: 0;
		right: 24px;

		p {
			padding-top: 4px;
		}
		p.center-align {
			text-align: center;
		}
		p.bold {
			font-weight: bold;
		}
	}

	&__drawing-multiCondenser {
		position: relative;
		float: right;

		p {
			padding-top: 4px;
			padding-left: 12px;
		}
		p.center-align {
			text-align: center;
		}
		p.bold {
			font-weight: bold;
		}
	}

	&__table-heading {
		font-size: $font-size-m;
		font-weight: $font-weight-bold;
		margin-bottom: 16px;
	}

	&__content {
		margin-bottom: 64px;
	}

	&__footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: auto;

		p {
			margin-bottom: 4px;
		}
	}

	&__info-section {
		position: relative;
	}

	&__calculation-type {
		position: absolute;
		top: 0;
		right: 0;
	}

	//================================
	// Tables
	//=================================
	.data-table,
	.info-table {
		&__multiCondenser {
			width: 100% !important;
			table-layout: auto;

			tr.break-row {
				margin-top: 24px;
				display: block;
			}

			th,
			td {
				padding: 4px 2px;
				text-align: start;
			}

			thead th {
				font-size: $font-size-m;
				padding-bottom: 16px;
			}
		}

		width: 75%;
		table-layout: auto;

		tr.break-row {
			margin-top: 24px;
			display: block;
		}

		th,
		td {
			padding: 4px 2px;
			text-align: start;
		}

		thead th {
			font-size: $font-size-m;
			width: 20%;
			padding-bottom: 16px;
		}
	}
}
