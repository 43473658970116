.offer-info {
	width: 100%;
	max-width: 1440px;
	padding: 24px;
	box-shadow: 2px 4px 17px rgba(0, 0, 0, 0.15);

	&__header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 24px;

		p {
			color: $color-gray-6;
			font-size: $font-size-xs;
		}
	}

	&__logo {
		width: 260px;
		height: auto;
		object-fit: contain;
	}

	&__form-container {
		// max-width: 628px;
	}

	&__note {
		width: 628px;
		background-color: rgba($color-gray-5, 0.1);
		font-size: $font-size-s;
		padding: 12px;
		margin-bottom: 24px;
	}

	//================================
	// Position breakdown
	//=================================

	.position-breakdown {
		border: 1px solid rgba(138, 138, 138, 0.5);
		margin-bottom: 16px;

		&__header {
			background: rgba($color-gray-4, 0.7);
			padding: 12px;

			h3 {
				font-weight: $font-weight-bold;
			}
		}

		//================================
		// Position Quantity input
		//=================================
		.position-quantity-input {
			border: none;
			font-size: $font-size-s;
			background-color: transparent;
			width: 50px;

			.ant-input-number-handler-wrap {
				border: none;
				opacity: 1;
				background-color: transparent;
			}

			.ant-input-number-handler,
			.ant-input-number-handler {
				&-up,
				&-down {
					height: 50% !important;
					border: none;
				}

				&:hover {
					background-color: transparent;
					.ant-input-number-handler-down-inner,
					.ant-input-number-handler-up-inner {
						color: $color-black;
					}
				}
			}
		}
	}

	.position-breakdown-table {
		.ant-table-expanded-row .ant-table-cell:first-child {
			background-color: $color-white;
			padding-left: 48px;
		}
	}

	//================================
	// pricing breakdown breakdown
	//=================================
	.pricing-breakdown-table {
		border-collapse: collapse;
		border-spacing: 0 8px;
		width: 100%;

		thead {
			background-color: rgba(229, 229, 229, 0.3);
		}

		th,
		td {
			padding: 4px 16px;
		}

		.tbody-list-price {
			td {
				padding: 16px;
			}
		}

		tfoot {
			border-top: 1px solid #f0f0f0;

			td {
				padding: 16px;
			}

			.total-price-row {
				font-size: $font-size-m;

				.rounded-price {
					font-size: $font-size-m;
					width: 120px;

					input {
						font-weight: $font-weight-bold;
					}
				}

				background-color: rgba(229, 229, 229, 0.3);
			}
		}

		.input-number-underline {
			background-color: transparent;
		}
	}

	//================================
	// Bottom line price
	//=================================
	.bottom-line {
		font-size: $font-size-m;
		display: flex;
		justify-content: space-between;
		padding: 16px;
		background: rgba($color-gray-4, 0.2);
		margin-bottom: 24px;
	}

	//================================
	// Offer notes
	//=================================
	.offer-info-form {
		font-size: $font-size-s;
		label {
			font-size: $font-size-s;
			white-space: normal;
			word-wrap: break-word;
		}

		input,
		.ant-select {
			font-size: $font-size-s;
		}

		&__note {
			width: 628px;
			background-color: rgba($color-gray-5, 0.1);
			font-size: $font-size-s;
			padding: 12px;
		}
	}

	.offer-info-form__draggable-field {
		display: flex;
		gap: 12px;
	}

	.draggable-field__handle {
		cursor: grab;
		margin-top: 9px;
	}

	.draggable-field__content {
		position: relative;
		flex-grow: 1;
	}

	.offer-info-form__drag-overlay {
		background-color: $color-white;
	}

	.offer-info-form--custom-field {
		position: relative;
		gap: 12px;

		.form-item-icon {
			margin-top: 9px;
		}
	}
}

.offer-custom-field {
	margin-bottom: 48px;
	width: 100%;
}

.ant-form-item.offer-custom-field__input-content {
	flex-grow: 1;
	margin-right: 0;
}

.offer-custom-field__controls {
	display: flex;
	flex-direction: column;
	gap: 12px;

	position: absolute;
	top: 0;
	right: -16px;
	transform: translateX(100%);
}

.offer-custom-field__controls .ant-form-item {
	margin-right: 0;
}

//================================
// Extras
//=================================
.extras-form {
	label,
	input {
		font-size: $font-size-s;
	}
}
//================================
// Currency group
//=================================
.currency-group {
	display: flex;
}
