.pdf-page {
	box-shadow: 2px 4px 17px rgba(0, 0, 0, 0.15);
	padding: 64px;
	width: 1440px;
	font-size: $font-size-m;
	line-height: 1.28;
	color: $color-black;

	.ant-divider {
		color: $color-black;
		border-color: $color-black;
	}
}
