$color-button-primary: $color-primary;
$color-button-primary-hover: $color-white;

.dashboard-table-dropdown-button {
	color: $color-button-primary;
	width: 100%;
	height: 100%;
}

.dashboard-table-dropdown-button:hover {
	color: $color-button-primary-hover;
	background-color: $color-button-primary;
}
.dashboard-table-dropdown-button:focus {
	color: $color-button-primary-hover;
	background-color: $color-button-primary;
}

.dashboard-table-dropdown-button-icon {
	font-size: 1.5em;
}
