.multiple-selection {
	border-radius: 0;
	position: relative;

	&__selector {
		border: 1px solid $color-black;
		padding: 10.5px;
		cursor: pointer;
	}

	&__title {
		padding: 0 8px;
	}

	&__option-container {
		background-color: $color-white;
		position: absolute;
		width: 100%;
		z-index: 999;
		display: none;
		visibility: hidden;
		opacity: 0;
		-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
		transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
		margin-top: 8px;

		border: 1px solid $color-black;
		box-shadow: 10px 15px 27px 3px rgba(0, 0, 0, 0.05);

		&.open {
			visibility: visible;
			opacity: 1;
			display: block;
		}
	}

	.ant-checkbox-group-item {
		display: flex;
		margin-right: 0;
	}

	.ant-checkbox-group {
		width: 100%;
	}

	.ant-checkbox-group-item {
		padding: 10px;
		font-size: $font-size-s;
		width: 100%;

		&:hover {
			background-color: rgba($color-gray-4, 0.7);
		}
	}
}

.multiple-selection-drawing {
	border-radius: 0;
	position: relative;

	&__selector {
		border: 1px solid $color-black;
		padding: 5px 10px;
		cursor: pointer;
		.vahterus-button {
			&__icon {
				margin-right: 0px !important;
				margin-bottom: 3px;
				width: 12px;
			}
		}
	}

	&__title {
		padding: 0 8px;
		text-transform: uppercase;
		font-size: $font-size-m;
	}

	&__option-container {
		background-color: $color-white;
		position: absolute;
		width: 100%;
		z-index: 999;
		display: none;
		visibility: hidden;
		opacity: 0;
		-webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
		transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
		margin-top: 8px;

		border: 1px solid $color-black;
		box-shadow: 10px 15px 27px 3px rgba(0, 0, 0, 0.05);

		&.open {
			visibility: visible;
			opacity: 1;
			display: block;
		}
	}

	.ant-checkbox-group-item {
		display: flex;
		margin-right: 0;
	}

	.ant-checkbox-group {
		width: 100%;
	}

	.ant-checkbox-group-item {
		padding: 10px;
		font-size: $font-size-s;
		width: 100%;

		&:hover {
			background-color: rgba($color-gray-4, 0.7);
		}
	}
}

.drawing-checkbox-group {
	display: grid;
	grid-template-columns: 1fr;

	.ant-checkbox-wrapper {
		padding-bottom: 16px;
	}
}
