.modal-form-condenser {
	&__section {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 16px;
	}

	&__heading {
		padding: 18px 0;
		color: $color-primary;
		font-size: $font-size-s;
		font-weight: $font-weight-bold;
	}
}
