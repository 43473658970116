$color-select-underline: $color-black;
$color-select-underline-active: $color-primary;
$color-select-underline-hot: $color-orange-2;
$color-select-background-hot: rgba($color-orange-2, 0.05);
$color-select-underline-cold: $color-blue-3;
$color-select-background-cold: rgba($color-blue-3, 0.05);
$color-select-underline-output: $color-green;
$color-select-background-output: rgba($color-green, 0.1);

.select-underline {
	border: none;
	outline: none;
	border-bottom: 1px solid $color-select-underline;
	padding-right: 0;
	padding-left: 0;

	$self: &;

	.ant-select-focused .ant-select-selector,
	.ant-select-selector:focus,
	.ant-select-selector:active,
	.ant-select-open .ant-select-selector {
		border-color: $color-select-underline-active !important;
		box-shadow: none !important;
	}

	.ant-select-selector {
		outline: none;
	}

	&:focus,
	&:hover {
		border-color: $color-select-underline-active;
		box-shadow: none !important;
	}

	.ant-select-selector {
		border: none !important;
	}

	&-hot {
		border-bottom: 1px solid $color-select-underline-hot;

		&:focus,
		&:hover {
			border-color: $color-select-underline-hot;
		}
	}

	&-cold {
		border-bottom: 1px solid $color-select-underline-cold;

		&:focus,
		&:hover {
			border-color: $color-select-underline-cold;
		}
	}

	&-output {
		border-bottom: 1px solid $color-select-underline-output;

		.ant-select-selector {
			background-color: $color-select-background-output !important;

			&#{ $self }-hot {
				background-color: $color-select-background-hot !important;
				border-bottom: 1px solid $color-select-underline-hot;
			}

			&#{ $self }-cold {
				background-color: $color-select-background-cold !important;
				border-bottom: 1px solid $color-select-underline-cold;
			}
		}
	}
}
