.preview-document {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px;

	&__calculation-pricing-select {
		margin-right: 16px;

		.ant-select-selector {
			border: 1px solid $color-black !important;
			height: 43px !important;
			display: flex;
			align-items: center;
		}
	}

	&__selections {
		display: flex;
		align-items: center;
	}

	&__selection-wrapper {
		padding: 12px;
		border: 1px solid $color-black;
		margin-left: 16px;

		.ant-checkbox-wrapper {
			font-size: $font-size-s;
		}
	}
}

//export drawing styles
.export-drawing-modal-wrapper {
	display: grid;
	width: 100%;
	grid-template-columns: 0.7fr auto 1fr;
	grid-gap: 24px;
	padding: 0px 24px;
	align-content: flex-start;

	.vahterus-button {
		&__export-drawing {
			text-transform: uppercase;
			margin: 8px;
			width: 90%;
		}
	}

	.custom-divider {
		height: 100%;
		width: 1px;
		background-color: rgba(0, 0, 0, 0.06);
	}

	h4 {
		color: rgba(0, 0, 0, 0.5);
		margin-bottom: 16px;
	}

	ul {
		list-style: none;
		width: 100%;

		li {
			padding-bottom: 16px;
		}
	}
}
