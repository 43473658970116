.expanded-properties {
	//background-color: white;
	//box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.08);
	//margin-top: 16px;
	padding: 24px;

	input,
	.ant-select {
		font-size: $font-size-s;
		width: 100%;
	}

	&__header {
		//background-color: $color-gray-4;;
	}

	.properties-input-group {
		margin-bottom: 28px;
	}

	&__info-text {
		max-width: 560px;
		margin-bottom: 32px;
		margin-left: 150px;
	}

	&__heading {
		font-size: $font-size-s;
		//background-color: red;
		flex: 1;
	}

	&__points {
		display: grid;
		grid-template-columns: 1fr 1fr 2fr;
		grid-column-gap: 12px;
	}

	&__note {
		color: $color-gray-6;
		font-size: $font-size-s;
		max-width: 650px;
		margin-bottom: 56px;
	}

	&__import-button {
		margin-bottom: 28px;
		display: flex;
		justify-content: flex-end;
	}

	&__chart {
		height: 372px;
		width: 100%;
	}

	.custom-label {
		padding: 6px;
		font-size: $font-size-s;
	}

	.ant-form-item {
		&-label {
			width: auto;
		}
	}

	.section-row {
		display: grid;
		grid-template-columns: repeat(10, 1fr);
		grid-column-gap: 12px;
		width: 90%;
	}

	.hot-label {
		font-size: $font-size-m;
		color: $color-hot-switch;
		font-weight: $font-weight-bold;
	}

	.cold-label {
		font-size: $font-size-m;
		color: $color-cold-switch;
		font-weight: $font-weight-bold;
	}
}
