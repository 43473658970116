.offer-details {
	display: flex;
	justify-content: space-between;
	margin-bottom: 24px;
	flex-wrap: wrap-reverse;
	//================================
	// Info table
	//=================================
	.info-table {
		th {
			font-weight: $font-weight-normal;
			text-align: start;
		}

		th,
		td {
			padding: 3px 16px;
			padding-left: 0;
			line-height: 14.08px;
			vertical-align: top;

			span {
				display: block;
				margin-bottom: 6px;
			}
		}
	}

	//================================
	// Offer table
	//=================================
	.offer-table {
		margin-bottom: 24px;

		th {
			text-align: start;
		}

		&__offer-row {
			font-size: 20px;

			th,
			td {
				font-weight: $font-weight-bold !important;
			}
		}

		&__date-row {
			font-size: 15px;
		}

		th,
		td {
			padding: 10px 16px;
			font-weight: $font-weight-normal;
		}
	}
}
