.login-page {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
}

.login {
	width: 560px;
	height: 320px;
	box-shadow: 4px 4px 20px 1px rgba(0, 0, 0, 0.08);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@include respond(tab-port) {
		width: 90%;
	}

	&__logo {
		margin-bottom: 24px;
	}

	&__heading {
		font-size: 25px;
		margin-bottom: 52px;
	}

	&__button {
		width: 243px;
	}
}
