.page-offer-documents {
	.page-section {
		margin: 37px 0;
	}

	.export-button-row {
		margin-bottom: 48px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		width: 100%;
	}

	.back-section {
		display: flex;
		margin-bottom: 24px;
		margin-left: 24px;
		align-items: center;

		h1 {
			font-size: $font-size-xlg;
			margin-bottom: 0;
			font-weight: $font-weight-bold;
		}

		&__button {
			margin-right: 32px;
		}
	}

	.ant-tabs-tabpane {
		padding: 24px 0;
		padding-left: 65px;
	}
}
