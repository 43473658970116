$color-button-primary: $color-primary;

.vahterus-button {
	border-radius: 0;

	&:not(:disabled) {
		&.ant-btn-default {
			background: $color-white;
			color: $color-button-primary;
			border-color: $color-button-primary;

			&:hover {
				color: $color-white;
				background: $color-button-primary;
			}
		}

		&.ant-btn-dashed {
			color: $color-button-primary;
			border-color: $color-button-primary;

			&:hover {
				border-color: $color-button-primary;
			}
		}

		&.ant-btn {
			color: $color-button-primary;
			border-color: $color-button-primary;

			&:hover {
				background: $color-button-primary;
				color: $color-white;
				border-color: $color-button-primary;
			}
		}

		&.ant-btn-primary {
			background-color: $color-button-primary;
			background: $color-button-primary;
			border-color: $color-button-primary;
			color: $color-white;
			text-transform: uppercase;
		}
	}

	&__icon {
		margin-right: 4px;
	}
}
