.thermo-input-group {
	//================================
	// Highlight fields
	//=================================
	.container-highlight {
		border: 1px solid $color-gray-7;
		padding: 12px;
	}

	//================================
	// Hidden fields
	//=================================
	.expand-button {
		margin-left: 160px;
		margin-top: 16px;
		margin-bottom: 16px;
		font-size: $font-size-s;
	}

	.hidden-fields {
		transition: all 0.4s ease-in-out;
		display: none;

		&.visible {
			display: block;
		}
	}

	//================================
	// In-out buttons
	//=================================
	.inout-buttons {
		margin-left: 160px;
		margin-bottom: 16px;

		.inout-button {
			font-size: $font-size-s;

			&--hidden {
				visibility: hidden;
			}

			&:first-child {
				margin-right: 8px;
			}
		}
	}

	.mixture-buttons {
		margin-left: 160px;
		margin-bottom: 16px;
		display: flex;

		.mixture-button {
			padding-left: 0;
			padding-right: 0;
			&:first-child {
				margin-right: 8px;
			}

			flex: 1;
		}
	}
}
