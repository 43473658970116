.offer-detail-pdf {
	height: 100%;

	&__header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 24px;
		//background-color: red;

		p {
			font-size: $font-size-m;
		}
	}

	&__logo {
		width: 200px;
		height: auto;
		object-fit: contain;
		margin-bottom: 24px;
		//background-color: cyan;
		align-self: center;
	}

	.preview-position-table {
		width: 100%;
		margin-bottom: 24px;

		th {
			text-align: start;
			border-bottom: 1px solid black;
		}

		tfoot td {
			border-top: 1px solid black;
		}

		th,
		td {
			padding: 12px 0;
		}

		tr.extra {
			opacity: 0.7;
		}
	}

	.bottom-line {
		//background-color: red;
		display: flex;
		justify-content: flex-end;
		margin-bottom: 48px;
	}

	.offer-info-table {
		width: 90%;
		margin-bottom: 24px;

		th,
		td {
			padding: 3px 0;
			vertical-align: top;
		}
	}

	pre {
		// width: 70%;
		white-space: pre-wrap;
		font-size: $font-size-m;
		line-height: 1.48;
	}
	.hide {
		display: none;
		height: 1px;
		pointer-events: none;
	}
}
