//==========================================
// Special info modal
//==========================================
.share-link {
	.ant-btn-primary {
		border-color: black;
	}
}

.modal-text-wrapper p {
	margin-top: 15px;
	margin-bottom: 0px;

	.ant-modal-body {
		padding-bottom: 0;
	}
}
