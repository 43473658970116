.hot-cold-inputs {
	margin-left: 160px;
	display: flex;

	.ant-form-item {
		flex-direction: column;
		font-size: $font-size-xs;

		&.hot-input {
			flex: 1;

			label {
				color: $color-hot-switch;
			}
		}

		&.cold-input {
			flex: 2;

			label {
				color: $color-cold-switch;
			}
		}

		&-label {
			width: auto;
			font-weight: bold;
		}
	}

	.hot-switch {
		background-color: $color-hot-switch;
		//margin-right: 22px;
	}

	.cold-switch {
		background-color: $color-cold-switch;
	}
}

.hot-cold-label {
	padding-top: 10px;
	padding-bottom: 10px;
}
