$font-size: 12px;
$font-size-title: 13px;

$color-border: rgba(0, 0, 0, 0.3);
$color-background: rgba(0, 0, 0, 0.03);

.hazard-analysis {
	display: flex;
	gap: 60px;
	padding: 36px;
	font-size: $font-size;

	& > div {
		width: 50%;
	}

	h3 {
		font-size: $font-size-title;
		font-weight: 600;
		margin-bottom: 18px;
	}

	.bold {
		font-weight: 600;
	}

	&__intro {
		font-size: $font-size-title;
		margin-bottom: 42px;
	}

	&__section {
		margin: 32px 0 64px 0;

		&--bordered {
			border: 1px solid $color-border;
			padding: 16px 20px;
		}
	}

	&__controls {
		display: flex;
		gap: 24px;
	}

	.ant-form,
	.ant-select,
	.ant-select-item,
	.ant-select-item-option {
		font-size: $font-size;
	}

	.ant-select:not(.ant-select-customize-input) {
		.ant-select-selector {
			background-color: $color-background;
			border-color: $color-border;
			display: inline-flex;
		}
	}

	.ant-form-item {
		margin-bottom: 0;
	}

	.ha-factors-table {
		table-layout: fixed;
		width: 100%;

		th,
		td {
			padding: 16px;
			border: 1px solid $color-border;
		}

		// dummy th without header content, just for setting width only
		thead.dummy th {
			padding: 0;
			border: none;
		}

		td {
			&.editable {
				background-color: $color-background;

				.ant-form-item-control-input {
					min-height: unset;
				}
			}

			&.center {
				text-align: center;
				.ant-form-item {
					margin-left: 10px;
				}
			}
		}

		.ant-input {
			border: none;
			padding: 0;
			text-align: center;
			font-size: $font-size;
			background-color: transparent;
		}

		.ant-select:not(.ant-select-customize-input) {
			.ant-select-selector {
				background-color: transparent;
				border: none;
				padding: 0;
			}
		}
	}

	.ha-result-content {
		margin: 24px 0;

		&__row {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 16px;
			font-weight: 600;

			& > div {
				display: flex;
			}
		}

		&--safety-factors .ha-result-content__row {
			& > div:first-child {
				flex: 0 0 240px;
			}

			& > div:nth-child(2) {
				flex: 0 0 64px;
			}

			& > div:nth-child(3) {
				flex: 0 0 48px;
				height: 48px;
				align-items: center;
				justify-content: center;
				border: 1px solid $color-border;
			}
		}

		&--pressure .ha-result-content__row {
			& > div:first-child {
				flex: 0 0 320px;
			}

			& > div:nth-child(2) {
				flex: 0 0 48px;
				height: 48px;
				align-items: center;
				justify-content: center;
				border: 1px solid $color-border;
			}
		}
	}

	.ha-reference-table {
		margin-top: 32px;

		th,
		td {
			padding: 12px;
			text-align: center;
			border: 1px solid $color-border;
		}
	}

	.ha-footnote {
		margin-top: 32px;
		line-height: 1.8;

		ul {
			list-style: none;
			margin-top: 16px;

			li {
				padding-left: 8px;
				text-indent: -8px;

				&:before {
					content: '- ';
				}
			}
		}
	}
}

.ant-select-dropdown--small {
	.ant-select-item-option {
		font-size: $font-size;
	}
}

.ha-print-preview {
	position: fixed;
	top: 0;

	background: #fff;
	width: 100vw;
	height: 100vh;
	padding: 16px;
	overflow: scroll;

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;

	h3 {
		font-size: $font-size;
		font-weight: 600;
	}

	.bold {
		font-weight: 600;
	}

	.translated-content {
		color: $color-border;
	}

	&__content {
		width: 1440px;
		height: 2037px;
		border: 1px solid $color-border;
		padding: 90px;
		font-size: $font-size;
		line-height: 2.5;
	}

	&__heading {
		display: flex;
		justify-content: space-between;

		img {
			height: 24px;
		}

		.heading__content {
			display: flex;
			gap: 6px;
		}
	}

	&__recipient {
		display: flex;
		gap: 90px;
		padding: 60px 0;
		border-bottom: 1px solid $color-border;

		.recipient {
			&__column {
				flex: 1;
				width: 50%;
			}

			&__row,
			&__row-label {
				display: flex;

				& > div {
					flex: 1;
				}
			}

			&__row {
				// margin-bottom: 4px;
			}

			&__row-label {
				gap: 6px;
			}
		}
	}

	&__result {
		padding: 60px 0;

		.result {
			&__content {
				display: flex;
				justify-content: space-between;
			}

			&__column {
				&:first-child {
					flex: 0 0 768px;
				}
			}

			&__section {
				margin: 54px 0;
				display: flex;
				flex-direction: column;
				gap: 18px;

				&--three-cols .result__row {
					& > div:first-child {
						flex: 0 0 396px;
					}

					& > div:nth-child(2) {
						flex: 0 0 60px;
						text-align: center;
					}

					& > div:nth-child(3) {
						padding: 0 24px;
					}
				}

				&--two-cols .result__row {
					justify-content: flex-end;

					& > div:first-child {
						flex: 0 0 360px;
					}

					& > div:nth-child(2) {
						flex: 0 0 60px;
						text-align: center;
					}
				}
			}

			&__row {
				display: flex;
				gap: 24px;
				line-height: 2;
			}
		}
	}
}
