/**
 * Sass Mixins
 */

@mixin clearfix {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

/*
$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/

@mixin respond($breakpoint) {
	@if $breakpoint == phone {
		@media (max-width: 600px) {
			@content;
		} //600px
	}

	@if $breakpoint == tab-port {
		@media (max-width: 900px) {
			@content;
		} //900px
	}

	@if $breakpoint == tab-land {
		@media (max-width: 1200px) {
			@content;
		} //1200px
	}

	@if $breakpoint == scaling-desktop {
		@media (max-width: 1700px) {
			@content;
		} //1700px
	}

	@if $breakpoint == big-desktop {
		@media (min-width: 1800px) {
			@content;
		} //1800
	}
}
