/**
 * Sass animations
 */

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 50%;
	}

	100% {
		opacity: 100%;
	}
}
