/**
 * Typography rules
 */
//
body,
pre {
	font-family: AktivGroteskEx;
	font-weight: $font-weight-normal;
	line-height: 1.28;
	color: $color-black;
	font-size: $default-font-size;
}

@font-face {
	font-family: 'AktivGroteskEx';
	src: url(../../assets/fonts/AktivGroteskEx_W_Rg.woff) format('woff');
	font-weight: $font-weight-normal;
}

@font-face {
	font-family: 'AktivGroteskEx';
	src: url(../../assets/fonts/AktivGroteskEx_W_Lt.woff) format('woff');
	font-weight: $font-weight-light;
}

@font-face {
	font-family: 'AktivGroteskEx';
	src: url(../../assets/fonts/AktivGroteskEx_W_Bd.woff) format('woff');
	font-weight: $font-weight-bold;
}
