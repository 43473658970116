$color-border-primary: $color-blue;

.dashboard-menu {
	margin-top: 5px;

	&::after {
		content: '';
		border-bottom: solid 1px $color-border-primary;
	}
}
.dashboard-menu-item {
	margin: 0 !important;
	padding: 0 20px !important;
	width: 140px !important;
	text-align: center;
}

.dashboard-menu-item.ant-menu-item-selected {
	font-weight: bold;
}

.dashboard-button-row {
	display: flex;
	float: right;
	margin-top: 15px;

	.vahterus-button {
		text-transform: uppercase;
	}

	.vahterus-button:not(:last-child) {
		margin-right: 8px;
	}
}
