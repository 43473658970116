.sizing-form {
	$color-form-background: $color-gray-4;
	padding: 16px;

	.button-import-imp {
		margin-right: 8px;
	}

	&__main-form {
		display: grid;
		grid-template-columns: 1.5fr 1fr;
		grid-column-gap: 16px;
		margin-top: 16px;

		@include respond(scaling-desktop) {
			grid-template-columns: 1fr;
		}
	}

	&__heading {
		color: $color-primary;
		padding: 16px;
		margin-bottom: 0;
		font-size: $font-size-lg;

		&--connections {
			padding: 16px 0px 16px 16px;
			margin-bottom: 0px;
		}
	}

	&__container {
		background-color: $color-white;
		box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.08);

		&--connection {
			margin-bottom: 16px;

			@include respond(tab-land) {
				margin-top: 16px;
			}
		}

		&--expanded-properties {
			margin-top: 16px;
		}

		&--application-form {
			display: grid;
			grid-template-columns: 0.5fr 1fr;
		}
	}

	&__container-ai {
		background-color: $color-white;
		border: 1px solid #dbdbdb;
		padding: 10px;

		&--connection {
			margin-bottom: 16px;

			@include respond(tab-land) {
				margin-top: 16px;
			}
		}

		&--expanded-properties {
			margin-top: 16px;
		}

		&--application-form {
			display: grid;
			grid-template-columns: 0.5fr 1fr;
		}
	}

	&__input-group {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 30px;
		padding: 48px 16px;
		padding-top: 0;
		height: 100%;

		@include respond(phone) {
			grid-template-columns: 1fr;
		}

		&--connections {
			padding: 16px;
		}
	}

	&__input-group-construction {
		display: grid;
		grid-template-columns: 50% 45%;
		grid-column-gap: 30px;
		padding: 48px 16px;
		padding-top: 0;
		height: 100%;

		@include respond(phone) {
			grid-template-columns: 1fr;
		}

		&--connections {
			padding: 16px;
		}
	}

	&__input-group-ai {
		// display: grid;
		// grid-template-columns: 1fr 1fr;
		// grid-column-gap: 30px;
		// padding: 48px 16px;
		// padding-top: 0;
		// height: 100%;

		// @include respond(phone) {
		// 	grid-template-columns: 1fr;
		// }
	}

	//==========================================
	// Error message
	//==========================================
	&__error {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 24px;
		margin-right: 24px;
	}

	.required-alert-wrapper {
		display: flex;
		justify-content: flex-start;

		.required-alert {
			margin-top: 15px;
			padding: 7px 15px;
			text-align: left;
		}
	}

	//==========================================
	// Connection row
	//==========================================
	.connection-row {
		display: grid;
		grid-template-columns: 1fr 1fr 80px 85px;

		div {
			display: block;
			word-wrap: unset;
		}
		.input-blank-label::before {
			content: ' ';
			height: 2rem;
		}
		.input-number-underline__addonAfter {
			font-size: $font-size-xs;
		}
	}

	//==========================================
	// Expanded properties
	//==========================================
	.expanded-properties-header {
		background-color: #f8f8f8;
		display: flex;
		align-items: center;
		//background-color: yellow;

		p {
			margin-bottom: 0;
			padding: 16px;
			//background-color: red;
		}
	}
}

.form-modal,
.sizing-form {
	//==========================================
	// Input style
	//==========================================
	label {
		font-size: $font-size-s;
		white-space: normal;
		word-wrap: break-word;
	}

	input,
	.ant-select {
		font-size: $font-size-s;
		width: 100%;
	}

	.input-number-underline,
	.input-underline {
		width: 100%;
	}

	input {
		height: 33px;
	}

	.input-number-underline {
		height: 33px;
	}

	.ant-form-item-control-input-content {
		display: flex;
	}

	.by-pass-checkbox {
		.ant-form-item-control-input-content {
			justify-content: center;
		}
		.ant-checkbox-inner,
		.ant-checkbox-input {
			transform: scale(1.3);
		}
	}

	.ant-form-item {
		margin-bottom: 6px;
		flex: 1;

		&:not(:last-child) {
			margin-right: 4px;
		}

		&.required-group .ant-form-item-label:before {
			display: inline-block;
			margin-right: 4px;
			color: #ff4d4f;
			font-size: 14px;
			font-family: SimSun, sans-serif;
			line-height: 1;
			content: '*';
		}

		&-label {
			width: 160px;
		}

		&-control {
			flex: 1;
		}
	}

	//==========================================
	// Hot cold
	//==========================================
	.hot-cold-label {
		display: flex;
		justify-content: space-between;
		font-size: $font-size-s;
		.button-temp-pres {
			padding: 0;
			width: 26%;
		}
		&__container {
			width: 100%;
			display: flex;
			margin-left: 155px;
		}
		&__hot {
			color: $color-hot-switch;
			font-weight: $font-weight-bold;
			flex: 1;
		}

		&__cold {
			color: $color-cold-switch;
			font-weight: $font-weight-bold;
			flex: 1;
		}
	}
}

//==========================================
// Customized form for Hazard Analysis
//==========================================
.sizing-form--ha {
	display: flex;
	gap: 16px;
	flex-wrap: wrap;

	.sizing-form__container {
		flex-grow: 1;

		&--pshe {
			padding-right: 64px;
		}

		form {
			// max-width: 680px;
			padding: 16px;
		}

		.ant-form-item {
			position: relative;

			&-label {
				width: 275px;
			}

			&-control {
				width: fit-content;
			}

			&-description {
				position: absolute;
				top: 8px;
				right: -16px;
				transform: translateX(100%);
				font-size: 12px;
			}
		}
	}
}

.disabled-text-black {
	.ant-input-disabled {
		color: black;
	}

	span {
		flex: 1;

		p {
			max-width: 160px;
			margin-left: 16px;
		}
	}
}
