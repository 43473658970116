.edit-tab {
	.ant-tabs-tab {
		position: relative;

		.tab-save-indicator {
			height: 5px;
			width: 5px;
			background-color: $color-gray-5;
			border-radius: 50%;
			display: inline-block;
			position: absolute;
			left: 8px;
			top: 14px;
		}
		.ant-tabs-tab-btn {
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.ant-tabs-tab-active {
		.tab-save-indicator {
			background-color: $color-primary;
		}
	}
}
