//==========================================
// Special info modal
//==========================================
.special-info {
	&__header {
		font-weight: $font-weight-bold;
		font-size: $font-size-s;
	}

	&__section {
		&:not(:last-child) {
			margin-bottom: 24px;
		}
	}

	&__group {
		display: flex;
		font-size: $font-size-s;
		p {
			flex: 1;
			margin-bottom: 4px;
		}
	}
}
